<div *ngIf="!mobileEditorToggle.IsMobile">
  <br />
  <ion-toggle
    class="mobilePreviewSwitch"
    (ionChange)="mobileEditorToggle.switchEditorToMobile()"
    [checked]="mobileEditorToggle.IsMobileEditor"
  >
    <ion-icon
      slot="start"
      [name]="
        mobileEditorToggle.IsMobileEditor ? 'phone-portrait' : 'desktop-outline'
      "
    ></ion-icon>
    {{
      mobileEditorToggle.IsMobileEditor
        ? "Widok mobilny"
        : "Widok
desktopowy"
    }}
  </ion-toggle>
</div>
