import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { IonicModule } from "@ionic/angular";
import { PinchZoomModule } from "@meddv/ngx-pinch-zoom";
import { TranslateModule } from "@ngx-translate/core";
import { PdfViewerModule } from "ng2-pdf-viewer";
import { NgxJoditComponent } from "ngx-jodit";
import { RoleFriendlyNamePipe } from "../pipes/role-friendly-name.pipe";
import { SanitizeDOMPipe } from "../pipes/sanitize-dom.pipe";
import { AdminDataLevelSwitcherComponent } from "./components/admin-data-level-switcher/admin-data-level-switcher.component";
import { AdminFooterComponent } from "./components/admin-footer/admin-footer.component";
import { AdminLevelInstanceSelectorComponent } from "./components/admin-level-instance-selector/admin-level-instance-selector.component";
import { ConfirmationModalComponent } from "./components/confirmation-modal/confirmation-modal.component";
import { DataLevelSwitcherComponent } from "./components/data-level-switcher/data-level-switcher.component";
import { DataPlaceholderComponent } from "./components/data-placeholder/data-placeholder.component";
import { FilePreviewModalComponent } from "./components/file-preview-modal/file-preview-modal.component";
import { GlobalLoaderComponent } from "./components/global-loader/global-loader.component";
import { ImageSkeletonComponent } from "./components/image-skeleton/image-skeleton.component";
import { LegalLockComponent } from "./components/legal-lock/legal-lock.component";
import { MobileEditorToggleComponent } from "./components/mobile-editor-toggle/mobile-editor-toggle.component";
import { ModalHeaderComponent } from "./components/modal-header/modal-header.component";
import { NoOrganizationInFeedComponent } from "./components/no-organization-in-feed/no-organization-in-feed.component";
import { NotificationsListComponent } from "./components/notifications-list/notifications-list.component";
import { NotificationModalComponent } from "./components/notifications-list/partials/notification-modal/notification-modal.component";
import { NotificationsSkeletonComponent } from "./components/notifications-list/partials/notifications-skeleton/notifications-skeleton.component";
import { OrganizationsBadgesComponent } from "./components/organizations-badges/organizations-badges.component";
import { TileSkeletonComponent } from "./components/tile-skeleton/tile-skeleton.component";
import { UniversalHeaderSubpageComponent } from "./components/universal-header-subpage/universal-header-subpage.component";
import { UniversalHeaderComponent } from "./components/universal-header/universal-header.component";
import { FooterComponent } from "./components/footer/footer.component";
import { HiddenSupportComponent } from "./components/hidden-support/hidden-support.component";
import { NoScreensComponent } from "./components/no-screens/no-screens.component";


@NgModule({
  declarations: [
    GlobalLoaderComponent,
    ModalHeaderComponent,
    DataPlaceholderComponent,
    ConfirmationModalComponent,
    FilePreviewModalComponent,
    ImageSkeletonComponent,
    DataLevelSwitcherComponent,
    TileSkeletonComponent,
    UniversalHeaderComponent,
    UniversalHeaderSubpageComponent,
    NotificationsListComponent,
    NotificationsSkeletonComponent,
    NotificationModalComponent,
    MobileEditorToggleComponent,
    AdminDataLevelSwitcherComponent,
    AdminLevelInstanceSelectorComponent,
    NoOrganizationInFeedComponent,
    OrganizationsBadgesComponent,
    AdminFooterComponent,
    LegalLockComponent,
    RoleFriendlyNamePipe,
    FooterComponent,
    HiddenSupportComponent,
    NoScreensComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule,
    NgxJoditComponent,
    PinchZoomModule,
    PdfViewerModule,
    FormsModule,
    RouterModule,
    SanitizeDOMPipe
  ],
  exports: [
    GlobalLoaderComponent,
    TranslateModule,
    NgxJoditComponent,
    ModalHeaderComponent,
    DataPlaceholderComponent,
    ConfirmationModalComponent,
    PinchZoomModule,
    FilePreviewModalComponent,
    ImageSkeletonComponent,
    DataLevelSwitcherComponent,
    TileSkeletonComponent,
    UniversalHeaderComponent,
    UniversalHeaderSubpageComponent,
    MobileEditorToggleComponent,
    AdminDataLevelSwitcherComponent,
    AdminLevelInstanceSelectorComponent,
    NoOrganizationInFeedComponent,
    OrganizationsBadgesComponent,
    AdminFooterComponent,
    LegalLockComponent,
    RoleFriendlyNamePipe,
    SanitizeDOMPipe,
    FooterComponent,
    HiddenSupportComponent,
    NoScreensComponent
  ],
})
export class SharedModule {}
