import { Component, OnInit } from '@angular/core';
import { IMenuItem } from './interfaces/i-menu-item';
import { App as CapacitorApp } from '@capacitor/app';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from './global-services/alert.service';
import { UserAccountService } from './global-services/user-account.service';
import { register } from 'swiper/element/bundle';


register();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    public userAccountService: UserAccountService,
    private _alertService: AlertService,
    private _translate: TranslateService
  ) {
    CapacitorApp.addListener('backButton', ({ canGoBack }) => {
      console.log(canGoBack);
      if (!canGoBack) {
        this.showCloseAppAlert();
      } else {
        window.history.back();
      }
    });
  }

  private deferredPrompt: any;

  // public appPages: IMenuItem[] = [
  //   {
  //     title: 'admin',
  //     url: '/admin',
  //     icon: 'cog',
  //   },
  //   {
  //     title: 'accounts',
  //     url: '/admin/admin-accounts',
  //     icon: 'accessibility',
  //     isDivider: true,
  //   },
  // ];

  async ngOnInit(): Promise<void> {
     // Listen for the beforeinstallprompt event
    //  window.addEventListener('beforeinstallprompt', (event) => {
    //   // Prevent the mini-info bar from appearing on mobile
    //   event.preventDefault();
    //   // Stash the event so it can be triggered later
    //   this.deferredPrompt = event;

    //   // Show an alert to the user
    //   this._alertService.presentAlert(
    //     'Czy zainstalować Panel Admina?',
    //     'Panel Admina korzysta z technologii pozwalającej na zainstalowanie strony jako samodzielna aplikacja.',
    //     'Jeśli chcesz zainstalować aplikację, kliknij "OK" i postępuj zgodnie z instrukacjami przeglądarki. Jeśli nie, kliknij "Anuluj". Aplikację możesz zainstalować później w dowolnej chwili',
    //     [{ text: 'Ok', role: 'accept' }, {text: 'Anuluj', role: 'dismiss'}],
    //     () => {this.installApp()}
    //   )

    // });
  }

  installApp() {
    if (this.deferredPrompt) {
      // Show the install prompt
      this.deferredPrompt.prompt();
      // Wait for the user to respond to the prompt
      this.deferredPrompt.userChoice.then((choiceResult: any) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the A2HS prompt');
        } else {
          console.log('User dismissed the A2HS prompt');
        }
        // Clear the deferredPrompt variable
        this.deferredPrompt = null;
      });
    }
  }

  private showCloseAppAlert() {
    this._alertService.presentAlert(
      this._translate.instant('alerts.exit_app'),
      '',
      this._translate.instant('alerts.exit_app_content'),
      [
        {
          text: this._translate.instant('alerts.buttons.no'),
          role: 'cancel',
        },
        {
          text: this._translate.instant('alerts.buttons.yes'),
          handler: () => {
            CapacitorApp.exitApp();
          },
        },
      ],
      () => {
        console.log('Alert dismissed');
      }
    );
  }
}
